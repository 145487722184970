import './styles/StraightGauge.css';

function StraightGauge({ isVertical, value, min, max, unit }) {
  function valueToPercentage() {
    if (typeof value !== 'number') return 0;

    return Math.min(Math.max(0, ((value - min) / (max - min)) * 100), 100);
  }

  return (
    <div
      className={'StraightGauge' + (!isVertical ? '' : ' vertical')}
      style={{ '--value': `${valueToPercentage()}%` }}
    >
      <div className="bar">
        <div className="inner"></div>
      </div>
      <div className="labels-container">
        <div className="min">{min}</div>
        <div className="value-container">
          <div className="value">{value ?? '-'}</div>
          <div className="unit">{unit}</div>
        </div>
        <div className="max">{max}</div>
      </div>
    </div>
  );
}

export default StraightGauge;
