const STRESS_CATEGORIES = [
  {
    name: 'No Stress',
    max: 67,
    description:
      'Respiration rate below 60 BPM.\nMilk yield optimum.\nNo repro losses.\nRectal temperature below 38.5 °C (101.3 °F).',
    level: 1
  },
  {
    name: 'Stress Threshold',
    max: 71,
    description:
      'Respiration rate exceeds 60 BPM.\nMilk yield losses begin.\nRepro losses detectable.\nRectal temperature exceeds 38.5 °C (101.3 °F).',
    level: 2
  },
  {
    name: 'Mild-Moderate Stress',
    max: 79,
    description:
      'Respiration rate exceeds 75 BPM.\nRectal temperature exceeds 38 °C (102.2 °F).',
    level: 2
  },
  {
    name: 'Moderate-Severe Stress',
    max: 89,
    description:
      'Respiration rate exceeds 85 BPM.\nRectal temperature exceeds 40 °C (104 °F).',
    level: 3
  },
  {
    name: 'Severe Stress',
    max: 98,
    description:
      'Respiration rate 120-140 BPM.\nRectal temperature exceeds 41 °C (106 °F).',
    level: 3
  },
  {
    name: 'Fatal Stress',
    max: 1000,
    description:
      'Respiration rate 120-140 BPM.\nRectal temperature exceeds 41 °C (106 °F).',
    level: 4
  }
];

// temp and dewPoint are in celsius.
function getThi(temp, dewPoint) {
  // THI = T_dry + (0.36 * T_dew) + 41.2
  return Math.round(temp + 0.36 * dewPoint + 41.2);
}

function getStressCategory(value) {
  return STRESS_CATEGORIES.find(el => value <= el.max);
}

const thi = { STRESS_CATEGORIES, getThi, getStressCategory };
export default thi;
