import './styles/BatteryStatusHeaderCard.css';
import { ReactComponent as BatteryFullIcon } from 'assets/img/battery_full.svg';
import { ReactComponent as Battery1BarIcon } from 'assets/img/battery_1_bar.svg';

function BatteryStatusHeaderCard({ isLow }) {
  return (
    <div
      className={
        'BatteryStatusHeaderCard' +
        (isLow === undefined ? '' : isLow ? ' low' : ' normal')
      }
    >
      <div className="left">
        <div className="title">Battery Status</div>
        <div className="status">
          {isLow === undefined ? '-' : isLow ? 'LOW' : 'NORMAL'}
        </div>
      </div>
      {isLow === undefined ? null : !isLow ? (
        <BatteryFullIcon />
      ) : (
        <Battery1BarIcon />
      )}
    </div>
  );
}

export default BatteryStatusHeaderCard;
