import './styles/ChartWeather.css';
import ChartSkeleton from 'components/layouts/ChartSkeleton';

function createDataType(id, unit, name, getDataFromHistoricData) {
  return { id, name, unit, getDataFromHistoricData };
}
const DATA_TYPES = [
  createDataType(
    'temperature',
    '°C',
    'Temperature',
    data => data.temperature.outside
  ),
  createDataType(
    'dew-point',
    '°C',
    'Dew point',
    data => data.temperature.dewPoint
  ),
  createDataType(
    'heat-index',
    '°C',
    'Heat index',
    data => data.temperature.heatIndex
  ),
  createDataType(
    'wind-chill',
    '°C',
    'Wind chill',
    data => data.temperature.windChill
  ),
  createDataType('humidity', '%', 'Humidity', data => data.humidity),
  createDataType('wind-speed', 'km/h', 'Wind speed', data => data.wind.speed),
  createDataType(
    'wind-direction',
    '°',
    'Wind direction',
    data => data.wind.direction
  ),
  createDataType('barometric-pressure', 'mm Hg', 'Barometric pressure'),
  createDataType(
    'temperature-humidity-index',
    null,
    'THI',
    data => data.temperatureHumidityIndex
  ),
  createDataType('rainfall', 'mm', 'Rainfall', data => data.rainfall)
];

function ChartWeather({
  selectedDataSource,
  historicTime,
  dataType,
  historicData,
  onHistoricTimeChange,
  onDataTypeChange,
  onUpdate
}) {
  function getHistoricChartData() {
    if (historicData === undefined) return [];

    let historicChartData = [];
    if (dataType === 'barometric-pressure') {
      historicChartData = historicData.barometricPressure;
    } else {
      const getValueFromData = DATA_TYPES.find(
        el => el.id === dataType
      ).getDataFromHistoricData;

      historicChartData = historicData[selectedDataSource.id].map(data => ({
        time: data.time,
        value: getValueFromData(data)
      }));
    }

    return historicChartData;
  }

  function getCsvData() {
    function getFilename() {
      function getDateStr(time) {
        // ex: 31-01-22, 12-34
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year}, ${hour}-${minute}`;
      }

      // ex: FD - 7A, 04-28-22, 12-34, 24 hours.csv
      return [
        selectedDataSource.name,
        getDateStr(historicTime.start),
        `${historicTime.duration} hours`
      ].join(', ');
    }

    function getData() {
      function getDateStr(time) {
        // ex: 31-01-22 12:34
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hour}:${minute}`;
      }

      const dataTypeNames = DATA_TYPES.map(
        el => el.name + (!el.unit ? '' : ` (${el.unit})`)
      );
      const data = [['Time', ...dataTypeNames]];
      const selectedHistoricData = historicData[selectedDataSource.id];
      for (let i = 0; i < selectedHistoricData.length; i++) {
        const el = selectedHistoricData[i];

        const values = DATA_TYPES.map(dataTypeEl => {
          if (dataTypeEl.id === 'barometric-pressure') {
            return historicData.barometricPressure[i].value;
          }

          return dataTypeEl.getDataFromHistoricData(el);
        });
        const row = [getDateStr(el.time), ...values];

        data.push(row);
      }

      return data;
    }

    if (historicData === undefined) return;

    return { filename: getFilename(), data: getData() };
  }

  return (
    <div className="ChartWeather">
      <ChartSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType={dataType}
        historicData={historicData}
        historicChartData={getHistoricChartData()}
        csvData={getCsvData()}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange={onDataTypeChange}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export default ChartWeather;
