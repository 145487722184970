import { useState, useEffect } from 'react';
import farms from 'modules/farms';
import weatherLink from 'modules/weather-link';
import './styles/Authenticated.css';
import Sidebar from 'components/layouts/Sidebar';
import HomeWeather from 'components/layouts/HomeWeather';
import HomeSoil from 'components/layouts/HomeSoil';
import ChartWeather from 'components/layouts/ChartWeather';
import ChartSoil from 'components/layouts/ChartSoil';

const WEATHER_STATIONS = farms.selectedFarmData.weatherStations.map(el => ({
  ...el,
  isOnline: false,
  type: 'weather-station'
}));
const SOIL_DATA_LOGGERS = farms.selectedFarmData.soilDataLoggers.map(el => ({
  ...el,
  isOnline: true, // TODO: update this periodically
  type: 'soil-data-logger'
}));
// Max duration since last updated time to be considered online
const STATION_ONLINE_TIME_THRESHOLD = 30 * 60 * 1000;

function Authenticated() {
  const [view, setView] = useState('Home');
  // "Data source" is a weather station or soil data logger
  const [dataSources, setDataSources] = useState({
    weatherStations: WEATHER_STATIONS,
    soilDataLoggers: SOIL_DATA_LOGGERS,
    selected: WEATHER_STATIONS[0]
  });
  const [currentWeather, setCurrentWeather] = useState();
  const [prevThi24hrs, setPrevThi24hrs] = useState();
  const [weatherHistoricTime, setWeatherHistoricTime] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    duration: 24
  });
  const [weatherHistoricDataType, setWeatherHistoricDataType] =
    useState('temperature');
  const [weatherHistoricData, setWeatherHistoricData] = useState();
  const [soilHistoricTime, setSoilHistoricTime] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    duration: 24
  });
  const [soilHistoricDataType, setSoilHistoricDataType] =
    useState('temperature');
  const [soilHistoricData, setSoilHistoricData] = useState();

  // Init weatherLink event listeners
  useEffect(() => {
    weatherLink.on('current-data', data => {
      setCurrentWeather(data);

      // Update weather stations online status
      setDataSources(prev => {
        const next = { ...prev };
        for (const weatherStation of next.weatherStations) {
          weatherStation.isOnline =
            data[weatherStation.id]?.time >
            Date.now() - STATION_ONLINE_TIME_THRESHOLD;
        }

        return next;
      });
    });
    weatherLink.on('prev-thi-24hrs', data => {
      setPrevThi24hrs(data);
    });

    return () => {
      weatherLink.off('current-data');
      weatherLink.off('prev-thi-24hrs');
    };
  }, []);

  async function handleChartWeatherUpdate(startTime, duration) {
    try {
      const historicData = await weatherLink.getHistoricData(
        startTime,
        startTime + duration * 60 * 60 * 1000
      );
      setWeatherHistoricData(historicData);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChartSoilUpdate(startTime, duration) {
    // TODO
  }

  return (
    <div className="Authenticated">
      <Sidebar
        selectedView={view}
        weatherStations={dataSources.weatherStations}
        soilDataLoggers={dataSources.soilDataLoggers}
        selectedDataSource={dataSources.selected}
        onViewClick={view => setView(view)}
        onDataSourceClick={dataSource =>
          setDataSources(prev => ({ ...prev, selected: dataSource }))
        }
      />
      <div className="body">
        {view === 'Home' ? (
          dataSources.selected.type === 'weather-station' ? (
            <HomeWeather
              isOutdoor={dataSources.selected.location === 'outdoor'}
              currentWeather={currentWeather?.[dataSources.selected.id]}
              prevThi={prevThi24hrs?.[dataSources.selected.id]}
            />
          ) : (
            <HomeSoil />
          )
        ) : dataSources.selected.type === 'weather-station' ? (
          <ChartWeather
            selectedDataSource={dataSources.selected}
            historicTime={weatherHistoricTime}
            dataType={weatherHistoricDataType}
            historicData={weatherHistoricData}
            onHistoricTimeChange={time => setWeatherHistoricTime(time)}
            onDataTypeChange={dataType => setWeatherHistoricDataType(dataType)}
            onUpdate={handleChartWeatherUpdate}
          />
        ) : (
          <ChartSoil
            selectedDataSourceId={dataSources.selected.id}
            historicTime={soilHistoricTime}
            dataType={soilHistoricDataType}
            historicData={soilHistoricData}
            onHistoricTimeChange={time => setSoilHistoricTime(time)}
            onDataTypeChange={dataType => setSoilHistoricDataType(dataType)}
            onUpdate={handleChartSoilUpdate}
          />
        )}
      </div>
    </div>
  );
}

export default Authenticated;
