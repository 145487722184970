import './styles/BatteryLevelHeaderCard.css';
import { ReactComponent as BatteryFullIcon } from 'assets/img/battery_full.svg';
import { ReactComponent as Battery1BarIcon } from 'assets/img/battery_1_bar.svg';

function BatteryLevelHeaderCard({ value }) {
  const isLow = value === undefined ? undefined : value < 25;

  return (
    <div
      className={
        'BatteryLevelHeaderCard' +
        (isLow === undefined ? '' : isLow ? ' low' : ' normal')
      }
    >
      <div className="left">
        <div className="title">Battery Level</div>
        <div className="value">{value === undefined ? '-' : `${value}%`}</div>
      </div>
      {isLow === undefined ? null : !isLow ? (
        <BatteryFullIcon />
      ) : (
        <Battery1BarIcon />
      )}
    </div>
  );
}

export default BatteryLevelHeaderCard;
