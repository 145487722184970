import axios from 'axios';
import farms from 'modules/farms';
import thi from 'modules/thi';

const STATION_ID = farms.selectedFarmData.weatherLinkStationId;
const API_URL = {
  current: `https://rehg79afo7.execute-api.ap-southeast-1.amazonaws.com/prod/current/${STATION_ID}`,
  historic: `https://rehg79afo7.execute-api.ap-southeast-1.amazonaws.com/prod/historic/${STATION_ID}`
};
const API_CALL_PERIOD_MS = 5 * 60 * 1000;

const events = {
  'current-data': {
    data: null,
    handler: () => {}
  },
  'prev-thi-24hrs': {
    data: null,
    handler: () => {}
  }
};
let isInitd = false;

function init() {
  function pollData() {
    getCurrentData()
      .then(data => {
        events['current-data'].data = data;
        events['current-data'].handler(events['current-data'].data);
      })
      .catch(error => console.error(error));

    getPrevThi24hrs()
      .then(data => {
        events['prev-thi-24hrs'].data = data;
        events['prev-thi-24hrs'].handler(events['prev-thi-24hrs'].data);
      })
      .catch(error => console.error(error));
  }

  isInitd = true;
  pollData();
  setInterval(pollData, API_CALL_PERIOD_MS);
}

async function getHistoricData(startTime, endTime) {
  async function getRawData() {
    const startTimestamp = Math.floor(startTime / 1000);
    const endTimestamp = Math.floor(endTime / 1000);

    const res = await axios.get(
      `${API_URL.historic}` +
        `?start-timestamp=${startTimestamp}&end-timestamp=${endTimestamp}`
    );

    return res.data;
  }

  const rawData = await getRawData();
  const stationsRawData = {};
  rawData.sensors
    .filter(el => el.data_structure_type === 11)
    .forEach(el => {
      const stationId = el.data[0].tx_id;
      stationsRawData[stationId] = el.data;
    });

  const historicData = { time: { start: startTime, end: endTime } };
  for (const stationId in stationsRawData) {
    historicData[stationId] = stationsRawData[stationId].map(data => ({
      time: data.ts * 1000,
      temperature: {
        outside: fahrenheitToCelsius(data.temp_avg),
        dewPoint: fahrenheitToCelsius(data.dew_point_last),
        heatIndex: fahrenheitToCelsius(data.heat_index_last),
        windChill: fahrenheitToCelsius(data.wind_chill_last)
      },
      humidity: data.hum_last,
      wind: {
        speed: mphToKmh(data.wind_speed_avg),
        direction: data.wind_dir_of_prevail
      },
      temperatureHumidityIndex: thi.getThi(
        fahrenheitToCelsius(data.temp_avg),
        fahrenheitToCelsius(data.dew_point_last)
      ),
      rainfall: data.rainfall_mm
    }));
  }
  historicData.barometricPressure = rawData.sensors
    .find(el => el.data_structure_type === 13 && el.sensor_type === 242)
    .data.map(data => ({
      time: data.ts * 1000,
      value: inhgToMmhg(data.bar_absolute)
    }));

  console.log('in weather-link getHistoricData()', {
    rawData,
    stationsRawData,
    historicData
  });

  return historicData;
}

function on(event, listener) {
  if (events[event] === undefined) return;

  if (!isInitd) init();

  events[event].handler = listener;

  if (events[event].data !== null) {
    events[event].handler(events[event].data);
  }
}

function off(event) {
  if (events[event] === undefined) return;

  events[event].handler = () => {};
}

async function getCurrentData() {
  async function getRawData() {
    const res = await axios.get(API_URL.current);

    return res.data;
  }

  const rawData = await getRawData();
  const stationsRawData = {};
  rawData.sensors
    .filter(el => el.data_structure_type === 10)
    .map(el => el.data[0])
    .forEach(el => {
      stationsRawData[el.tx_id] = el;
    });

  const barometricPressure = rawData.sensors.find(
    el => el.data_structure_type === 12 && el.sensor_type === 242
  ).data[0].bar_absolute;

  const currentData = {};
  for (const stationId in stationsRawData) {
    const data = stationsRawData[stationId];
    const tempC = {
      outside: fahrenheitToCelsius(data.temp),
      dewPoint: fahrenheitToCelsius(data.dew_point),
      heatIndex: fahrenheitToCelsius(data.heat_index),
      windChill: fahrenheitToCelsius(data.wind_chill)
    };
    currentData[stationId] = {
      time: data.ts * 1000,
      isBatteryLow: data.trans_battery_flag === 1,
      temperature: tempC,
      humidity: data.hum,
      wind: {
        speed: mphToKmh(data.wind_speed_last),
        direction: data.wind_dir_last
      },
      barometricPressure: inhgToMmhg(barometricPressure),
      temperatureHumidityIndex: thi.getThi(tempC.outside, tempC.dewPoint),
      rainfall: {
        current: data.rainfall_last_15_min_mm,
        daily: data.rainfall_daily_mm,
        monthly: data.rainfall_monthly_mm,
        yearly: data.rainfall_year_mm
      }
    };
  }

  console.log('in weather-link getCurrentData()', {
    rawData,
    stationsRawData,
    currentData
  });

  return currentData;
}

async function getPrevThi24hrs() {
  async function getRawData() {
    const startTime = Math.floor(Date.now() / 1000) - 24 * 60 * 60;
    const endTime = startTime + 5 * 60;

    const res = await axios.get(
      `${API_URL.historic}?start-timestamp=${startTime}&end-timestamp=${endTime}`
    );

    return res.data;
  }

  const rawData = await getRawData();
  const stationsData = rawData.sensors.filter(
    el => el.data_structure_type === 11
  );

  const prevThi24hrs = {};
  stationsData
    .filter(el => el.data[0] !== undefined)
    .forEach(el => {
      const stationId = el.data[0].tx_id;
      const thiValue = thi.getThi(
        fahrenheitToCelsius(el.data[0].temp_avg),
        fahrenheitToCelsius(el.data[0].dew_point_last)
      );

      prevThi24hrs[stationId] = thiValue;
    });

  console.log('in weather-link getPrevThi24hrs()', { prevThi24hrs });

  return prevThi24hrs;
}

function fahrenheitToCelsius(value) {
  if (value === null) return null;

  const valueInCelsius = (value - 32) * (5 / 9);

  return parseFloat(valueInCelsius.toFixed(1));
}

function mphToKmh(value) {
  if (value === null) return null;

  const valueInKmh = value * 1.609;

  return Math.round(valueInKmh);
}

function inhgToMmhg(value) {
  if (value === null) return null;

  return value * 25.4;
}

const weatherLink = { getHistoricData, on, off };
export default weatherLink;
