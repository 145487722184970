import './styles/ChartSoil.css';
import ChartSkeleton from 'components/layouts/ChartSkeleton';

const DATA_TYPES = [
  {
    id: 'temperature',
    name: 'Temperature',
    unit: '°C'
  },
  {
    id: 'cation-exchange-capacity',
    name: 'Cation Exchange Capacity',
    unit: null
  },
  {
    id: 'diffuse-oxygen',
    name: 'Diffuse Oxygen',
    unit: 'mg/L'
  },
  {
    id: 'electroconductivity',
    name: 'Electroconductivity',
    unit: 'uS/cm'
  },
  {
    id: 'salinity',
    name: 'Salinity',
    unit: 'ppt'
  },
  {
    id: 'water-level',
    name: 'Water Level',
    unit: 'm'
  },
  {
    id: 'npk',
    name: 'NPK',
    unit: 'mg/L'
  },
  {
    id: 'ph',
    name: 'pH',
    unit: 'pH'
  },
  {
    id: 'moisture',
    name: 'Moisture',
    unit: '%'
  }
];

function ChartSoil({
  selectedDataSourceId,
  historicTime,
  dataType,
  historicData,
  onHistoricTimeChange,
  onDataTypeChange,
  onUpdate
}) {
  function getHistoricChartData() {
    function getValueFromDataType(data) {
      // TODO
    }

    if (historicData === undefined) return [];

    let historicChartData = [];
    historicChartData = historicData[selectedDataSourceId].map(data => ({
      time: data.time,
      value: getValueFromDataType(data)
    }));

    return historicChartData;
  }

  const historicChartData = getHistoricChartData();

  return (
    <div className="ChartSoil">
      <ChartSkeleton
        historicTime={historicTime}
        dataTypes={DATA_TYPES}
        selectedDataType={dataType}
        historicData={historicData}
        historicChartData={historicChartData}
        onHistoricTimeChange={onHistoricTimeChange}
        onDataTypeChange={onDataTypeChange}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export default ChartSoil;
