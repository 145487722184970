import './styles/HomeSoil.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
import BatteryLevelHeaderCard from 'components/misc/BatteryLevelHeaderCard';
import LastUpdatedTimeHeaderCard from 'components/misc/LastUpdatedTimeHeaderCard';
import Card from 'components/misc/Card';
import StraightGauge from 'components/misc/StraightGauge';
import GaugeChart from 'components/misc/GaugeChart';

// TODO: use data prop to update child data
function HomeSoil({ data }) {
  return (
    <div className="HomeSoil">
      <HomeSkeleton
        header={
          <>
            <BatteryLevelHeaderCard value={90} />
            <LastUpdatedTimeHeaderCard time={Date.now()} />
          </>
        }
        body={
          <>
            <TemperatureCard value={20} />
            <WaterLevelCard value={2.5} />
            <SalinityCard value={25} />
            <PhCard value={6} />
            <CationExchangCapacityCard value={50} />
            <DiffuseOxygenCard value={10} />
            <ElectroconductivityCard value={10000} />
            <NpkCard value={1000} />
            <MoistureCard value={50} />
          </>
        }
      />
    </div>
  );
}

function TemperatureCard({ value }) {
  return (
    <StraightGaugeCard
      title="Soil Temperature"
      isVertical
      value={value}
      min={-40}
      max={80}
      unit="°C"
    />
  );
}

function CationExchangCapacityCard({ value }) {
  return (
    <GaugeCard
      title="Soil Cation Exchange Capacity (CEC)"
      value={value}
      min={0}
      max={100}
    />
  );
}

function DiffuseOxygenCard({ value }) {
  return (
    <GaugeCard
      title="Soil Diffuse Oxygen (DO)"
      value={value}
      min={0}
      max={20}
      unit="mg/L"
    />
  );
}

function ElectroconductivityCard({ value }) {
  return (
    <GaugeCard
      title="Soil Electroconductivity (EC)"
      value={value}
      min={0}
      max={20000}
      unit="uS/cm"
    />
  );
}

function SalinityCard({ value }) {
  return (
    <StraightGaugeCard
      title="Soil Salinity"
      value={value}
      min={0}
      max={50}
      unit="ppt"
    />
  );
}

function WaterLevelCard({ value }) {
  return (
    <StraightGaugeCard
      title="Soil Water Level"
      isVertical
      value={value}
      min={0.0}
      max={5.0}
      unit="m"
    />
  );
}

function NpkCard({ value }) {
  return (
    <GaugeCard title="Soil NPK" value={value} min={1} max={2000} unit="mg/L" />
  );
}

function PhCard({ value }) {
  return (
    <StraightGaugeCard
      title="Soil pH"
      value={value}
      min={3.0}
      max={9.0}
      unit="pH"
    />
  );
}

function MoistureCard({ value }) {
  return (
    <GaugeCard title="Soil Moisture" value={value} min={0} max={100} unit="%" />
  );
}

function StraightGaugeCard({ title, isVertical, value, min, max, unit }) {
  return (
    <div className="StraightGaugeCard">
      <Card title={title}>
        <StraightGauge
          isVertical={isVertical}
          value={value}
          min={min}
          max={max}
          unit={unit}
        />
      </Card>
    </div>
  );
}

function GaugeCard({ title, value, min, max, unit }) {
  return (
    <div className="GaugeCard">
      <Card title={title}>
        <GaugeChart value={value} min={min} max={max} unit={unit} />
      </Card>
    </div>
  );
}

export default HomeSoil;
