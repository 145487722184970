import { useState } from 'react';
import './styles/ChartSkeleton.css';
import HistoricTimeSelectHeaderCard from 'components/misc/HistoricTimeSelectHeaderCard';
import DataTypeSelectHeaderCard from 'components/misc/DataTypeSelectHeaderCard';
import HistoricChartCard from 'components/misc/HistoricChartCard';
import ChartStatisticsCard from 'components/misc/ChartStatisticsCard';
import CsvDownloadCard from 'components/misc/CsvDownloadCard';

function ChartSkeleton({
  historicTime,
  dataTypes,
  selectedDataType,
  historicData,
  historicChartData,
  csvData,
  onHistoricTimeChange,
  onDataTypeChange,
  onUpdate
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleUpdateClick() {
    setIsLoading(true);
    await onUpdate(historicTime.start, historicTime.duration);
    setIsLoading(false);
  }

  function getChartOverlayState() {
    let overlayState;
    if (isLoading) {
      overlayState = 'loading';
    } else {
      const isStartTimeSame =
        Math.floor(historicTime.start / (60 * 1000)) ===
        Math.floor(historicData?.time.start / (60 * 1000));
      const isDurationSame =
        historicData === undefined
          ? false
          : Math.floor(
              (historicData.time.end - historicData.time.start) /
                (60 * 60 * 1000)
            ) === historicTime.duration;
      if (!isStartTimeSame || !isDurationSame) {
        overlayState = 'usage-message';
      }
    }

    return overlayState;
  }

  return (
    <div className="ChartSkeleton">
      <div className="header">
        <HistoricTimeSelectHeaderCard
          historicTime={historicTime}
          isDisabled={isLoading}
          onChange={onHistoricTimeChange}
          onUpdateClick={handleUpdateClick}
        />
        <DataTypeSelectHeaderCard
          els={dataTypes}
          selected={selectedDataType}
          isDisabled={isLoading}
          onChange={onDataTypeChange}
        />
      </div>
      <div className="body">
        <HistoricChartCard
          data={historicChartData}
          isThi={selectedDataType === 'temperature-humidity-index'}
          overlayState={getChartOverlayState()}
        />
        <div className="bottom">
          <ChartStatisticsCard
            data={historicChartData}
            unit={dataTypes.find(el => el.id === selectedDataType).unit}
          />
          <CsvDownloadCard filename={csvData?.filename} data={csvData?.data} />
        </div>
      </div>
    </div>
  );
}

export default ChartSkeleton;
