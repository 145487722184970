import thi from 'modules/thi';
import './styles/ThiHeaderCard.css';
import { ReactComponent as TriangleIcon } from 'assets/img/triangle.svg';
import { ReactComponent as DashIcon } from 'assets/img/remove.svg';

function ThiHeaderCard({ value, pctChange }) {
  function getStressCategory() {
    if (typeof value !== 'number') return;

    const stressCategory = thi.getStressCategory(value);
    stressCategory.cardClassName = `level-${stressCategory.level}`;

    return stressCategory;
  }

  const stressCategory = getStressCategory();

  return (
    <div className={'ThiHeaderCard ' + stressCategory?.cardClassName ?? ''}>
      <Tooltip message={stressCategory?.description ?? ''} />
      <div className="left">
        <div className="title">THI</div>
        <Value value={value} stressCategoryName={stressCategory?.name} />
      </div>
      <PctChange value={pctChange} />
    </div>
  );
}

function Tooltip({ message }) {
  return (
    <div className="Tooltip">
      <div className="icon">?</div>
      <div className="message">{message}</div>
    </div>
  );
}

function Value({ value, stressCategoryName }) {
  return (
    <div className="Value">
      <span className="value">{(value ?? '-') + ' '}</span>
      <span className="stress-category">{stressCategoryName ?? ''}</span>
    </div>
  );
}

function PctChange({ value }) {
  function getValueStr() {
    if (typeof value !== 'number') return;

    return `${value > 0 ? '+' : ''}${value.toFixed(1)}%`;
  }

  return value === undefined ? null : (
    <div className="PctChange">
      {value === 0 ? (
        <DashIcon />
      ) : (
        <TriangleIcon className={value > 0 ? 'triangle-up' : 'triangle'} />
      )}
      <div className="value">{getValueStr() ?? ''}</div>
    </div>
  );
}

export default ThiHeaderCard;
