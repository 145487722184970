import './styles/RainfallCard.css';
import { ReactComponent as WaterDropIcon } from 'assets/img/water_drop.svg';
import Card from 'components/misc/Card';

const STATES = [
  {
    maxInc: 0,
    name: 'Dry',
    barClass: ''
  },
  {
    maxInc: 10,
    name: 'Light',
    barClass: ''
  },
  {
    maxInc: 30,
    name: 'Moderate',
    barClass: 'level-1'
  },
  {
    maxInc: 60,
    name: 'Heavy',
    barClass: 'level-2'
  },
  {
    maxInc: Number.POSITIVE_INFINITY,
    name: 'Very Heavy',
    barClass: 'level-3'
  }
];

function RainfallCard({ current, daily, monthly, yearly }) {
  return (
    <div className="RainfallCard">
      <Card title="Rainfall">
        <div className="left">
          <Bar value={current} max={60} />
          <CurrentValue value={current} />
          <WaterDropIcon />
          <State value={current} />
        </div>
        <div className="divider"></div>
        <div className="right">
          <Stat label="Daily" value={daily} />
          <Stat label="Monthly" value={monthly} />
          <Stat label="Yearly" value={yearly} />
        </div>
      </Card>
    </div>
  );
}

function Bar({ value, max }) {
  function getLevelClass() {
    if (value === undefined) return '';

    return STATES.find(el => value <= el.maxInc).barClass;
  }

  function getHeightPct() {
    if (value === undefined) return 0;

    return (value / max) * 100;
  }

  return (
    <div className="Bar">
      <div
        className={'inner ' + getLevelClass()}
        style={{ '--bar-height': `${getHeightPct()}%` }}
      ></div>
    </div>
  );
}

function CurrentValue({ value }) {
  return (
    <div className="CurrentValue">
      <div className="value">{value?.toFixed(1) ?? '-'}</div>
      <div className="unit">mm</div>
    </div>
  );
}

function State({ value }) {
  function getState() {
    if (value === undefined) return '-';

    return STATES.find(el => value <= el.maxInc).name;
  }

  return <div className="State">{getState()}</div>;
}

function Stat({ label, value }) {
  return (
    <div className="Stat">
      <div className="label">{label}</div>
      <div className="value-container">
        <span className="value">{value?.toFixed(1) ?? '-'}</span>
        <span className="unit"> mm</span>
      </div>
    </div>
  );
}

export default RainfallCard;
