import './styles/WindDirectionCard.css';
import Card from 'components/misc/Card';

function WindDirectionCard({ directionAngle }) {
  return (
    <div className="WindDirectionCard">
      <Card title="Wind Direction">
        <div
          className="compass"
          style={{ '--compass-angle': `${directionAngle ?? 0}deg` }}
        >
          <div className="circle"></div>
          <div className="direction" id="N">
            N
          </div>
          <div className="direction" id="NE">
            NE
          </div>
          <div className="direction" id="E">
            E
          </div>
          <div className="direction" id="SE">
            SE
          </div>
          <div className="direction" id="S">
            S
          </div>
          <div className="direction" id="SW">
            SW
          </div>
          <div className="direction" id="W">
            W
          </div>
          <div className="direction" id="NW">
            NW
          </div>
        </div>
      </Card>
    </div>
  );
}

export default WindDirectionCard;
