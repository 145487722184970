import sha256 from 'crypto-js/sha256';

// WARNING: NOT SECURE, client-side user authentication
const USERS_LOGIN_DATA = [
  createUserLoginData(
    'fahrizal',
    '5cc6709b2908385842784e7a1937c8a2e592c4d806c7fdad016de80384456b5f'
  ),
  createUserLoginData(
    'alwi',
    '0a5dfd497f0ab8692f57dec4f77fdaad678bc3b7697aa9230a6420346902d8e5'
  ),
  createUserLoginData(
    'nik',
    '8fd027e7f11f7871f1c71cfdc0f4413ad18f0043d8983a62e2f964413f3a5fb5'
  ),
  createUserLoginData(
    'along',
    '574c03a5a1a2addef5703fc82242f5c6059269e03cc671c5ff1a640deb97884a'
  ),
  createUserLoginData(
    'raymond',
    '7978f833bc7f3e610000acc8429e4cb0c5e3c66b1b57b233896ae514ddde101d'
  ),
  createUserLoginData(
    'wardah',
    '12f8008a4c556a91b3e05cafe5ff7a41363deab13f7b3b8ed898b82b73df78fa'
  ),
  createUserLoginData(
    'admin',
    'c10be9ecea08c943dce4a6b233e1f78320f373b1da66c8b0e8923da918a03b30'
  ),
  createUserLoginData(
    'guest',
    '5b86047d9bdfd0f370470a983f4795f441b114329bf7feb8810b209c495c1263'
  )
];

function createUserLoginData(username, passwordHash) {
  return { username, passwordHash };
}

function isUserLoginValid(username, password) {
  function getPasswordHash(username, password) {
    return sha256(password + username).toString();
  }

  const user = USERS_LOGIN_DATA.find(el => el.username === username);
  if (!user) return false;

  const isPasswordValid =
    user.passwordHash === getPasswordHash(username, password);

  return isPasswordValid;
}

const auth = { isUserLoginValid };
export default auth;
