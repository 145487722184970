import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './styles/GaugeChart.css';

function GaugeChart({ value, min, max, unit }) {
  const canvasRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    // Get colors from CSS.
    const colorGaugeBackground = getComputedStyle(
      canvasRef.current
    ).getPropertyValue('--color-gauge-background');
    const colorGaugePrimary = getComputedStyle(
      canvasRef.current
    ).getPropertyValue('--color-gauge-primary');

    // Init chart.
    const ctx = canvasRef.current.getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [0, max],
            backgroundColor: [colorGaugePrimary, colorGaugeBackground]
          }
        ]
      },
      options: {
        circumference: 180,
        rotation: -90,
        cutout: '75%',
        borderWidth: 0,
        events: [],
        animation: { animateRotate: false },
        aspectRatio: 2
      }
    });

    return () => {
      chartRef.current.destroy();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const gaugeRange = max - min;
    const gaugeValue = Math.min(Math.max(value ?? 0, min), max) - min;
    chartRef.current.data.datasets[0].data = [
      gaugeValue,
      gaugeRange - gaugeValue
    ];
    chartRef.current.update();
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className="GaugeChart">
      <canvas ref={canvasRef}></canvas>
      <div className="labels-container">
        <div className="value">{value ?? '-'}</div>
        <div className="min">{min}</div>
        <div className="unit">{unit}</div>
        <div className="max">{max}</div>
      </div>
    </div>
  );
}

export default GaugeChart;
