import './styles/HomeSkeleton.css';

function HomeSkeleton({ header, body }) {
  return (
    <div className="HomeSkeleton">
      <div className="header">{header}</div>
      <div className="body">{body}</div>
    </div>
  );
}

export default HomeSkeleton;
