import './styles/DataTypeSelectHeaderCard.css';

function DataTypeSelectHeaderCard({ els, selected, isDisabled, onChange }) {
  return (
    <div className="DataTypeSelectHeaderCard">
      <div className="label">Data type</div>
      <select
        value={selected}
        disabled={isDisabled}
        onChange={e => onChange(e.target.value)}
      >
        {[...els]
          .sort((firstEl, secondEl) => {
            if (firstEl.id < secondEl.id) return -1;
            if (firstEl.id > secondEl.id) return 1;
            return 0;
          })
          .map(el => (
            <option key={el.id} value={el.id}>
              {`${el.name}` +
                (typeof el.unit !== 'string' ? '' : ` (${el.unit})`)}
            </option>
          ))}
      </select>
    </div>
  );
}

export default DataTypeSelectHeaderCard;
