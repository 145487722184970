import React from 'react';
import ReactDOM from 'react-dom';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'index.css';
import App from 'App';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState((state, props) => ({
      errors: [...state.errors, { error, errorInfo }]
    }));
  }

  render() {
    if (this.state.errors.length) {
      return (
        <pre>{this.state.errors.map(el => el.error.toString()).join('\n')}</pre>
      );
    }

    return this.props.children;
  }
}

Chart.register(annotationPlugin);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
