import './styles/HomeWeather.css';
import HomeSkeleton from 'components/layouts/HomeSkeleton';
import ThiHeaderCard from 'components/misc/ThiHeaderCard';
import BatteryStatusHeaderCard from 'components/misc/BatteryStatusHeaderCard';
import LastUpdatedTimeHeaderCard from 'components/misc/LastUpdatedTimeHeaderCard';
import TemperatureCard from 'components/misc/TemperatureCard';
import WindSpeedCard from 'components/misc/WindSpeedCard';
import WindDirectionCard from 'components/misc/WindDirectionCard';
import HumidityCard from 'components/misc/HumidityCard';
import BaroPressCard from 'components/misc/BaroPressCard';
import RainfallCard from 'components/misc/RainfallCard';

function HomeWeather({ isOutdoor, currentWeather, prevThi }) {
  function getThiPctChange() {
    if (currentWeather === undefined || prevThi === undefined) return;

    const currThi = currentWeather.temperatureHumidityIndex;

    return ((currThi - prevThi) / prevThi) * 100;
  }

  return (
    <div className="HomeWeather">
      <HomeSkeleton
        header={
          <>
            <ThiHeaderCard
              value={currentWeather?.temperatureHumidityIndex}
              pctChange={getThiPctChange()}
            />
            <BatteryStatusHeaderCard isLow={currentWeather?.isBatteryLow} />
            <LastUpdatedTimeHeaderCard time={currentWeather?.time} />
          </>
        }
        body={
          <>
            <TemperatureCard
              outside={currentWeather?.temperature.outside}
              dewPoint={currentWeather?.temperature.dewPoint}
              heatIndex={currentWeather?.temperature.heatIndex}
              windChill={currentWeather?.temperature.windChill}
            />
            <WindSpeedCard speed={currentWeather?.wind.speed} />
            <WindDirectionCard
              directionAngle={currentWeather?.wind.direction}
            />
            <HumidityCard humidity={currentWeather?.humidity} />
            {isOutdoor ? (
              <RainfallCard
                current={currentWeather?.rainfall.current}
                daily={currentWeather?.rainfall.daily}
                monthly={currentWeather?.rainfall.monthly}
                yearly={currentWeather?.rainfall.yearly}
              />
            ) : null}
            <BaroPressCard baroPress={currentWeather?.barometricPressure} />
          </>
        }
      />
    </div>
  );
}

export default HomeWeather;
