import './styles/ChartJsResponsive.css';

function ChartJsResponsive({ canvasRef }) {
  return (
    <div className="ChartJsResponsive">
      <div className="canvas-container">
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
}

export default ChartJsResponsive;
