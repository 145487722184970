import { useState, useEffect } from 'react';
import farms from 'modules/farms';
import 'App.css';
import Login from 'components/pages/Login';
import Authenticated from 'components/pages/Authenticated';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    document.title = farms.selectedFarmData.appTitle;
  }, []);

  function handleLoggedIn() {
    setIsAuthenticated(true);
  }

  return (
    <div className="App">
      {!isAuthenticated ? (
        <Login onLoggedIn={handleLoggedIn} />
      ) : (
        <Authenticated />
      )}
    </div>
  );
}

export default App;
