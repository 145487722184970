import './styles/LastUpdatedTimeHeaderCard.css';

function LastUpdatedTimeHeaderCard({ time }) {
  function getDateStr(time) {
    if (time === undefined) return '-';

    // Example: 31/01/2022 10:07
    const date = new Date(time);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

  return (
    <div className="LastUpdatedTimeHeaderCard">
      <div className="title">Last Updated</div>
      <div className="time">{getDateStr(time)}</div>
    </div>
  );
}

export default LastUpdatedTimeHeaderCard;
