import { useState } from 'react';
import auth from 'modules/auth';
import './styles/Login.css';
import mdtLogo from 'assets/img/mdt_logo.png';

function Login({ onLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  function handleLogIn() {
    if (!auth.isUserLoginValid(username, password)) {
      setErrorMessage('Invalid username or password');
    } else {
      setErrorMessage('');
      onLoggedIn();
    }
  }

  return (
    <div className="Login">
      <div className="login-card">
        <img className="mdt-logo" src={mdtLogo} alt="mdt-logo" />
        <input
          type="text"
          className="username"
          placeholder="username"
          value={username}
          autoFocus={true}
          onChange={e => setUsername(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') handleLogIn();
          }}
        />
        <input
          type="password"
          className="password"
          placeholder="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') handleLogIn();
          }}
        />
        <div className="error-message">{errorMessage}</div>
        <button type="button" className="log-in" onClick={handleLogIn}>
          Log in
        </button>
      </div>
    </div>
  );
}

export default Login;
