import './styles/Card.css';

function Card({ title, children }) {
  return (
    <div className="Card">
      <div className="title">{title}</div>
      <div className="body">{children}</div>
    </div>
  );
}

export default Card;
