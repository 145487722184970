import './styles/WindSpeedCard.css';
import Card from 'components/misc/Card';
import GaugeChart from 'components/misc/GaugeChart';

function WindSpeedCard({ speed }) {
  return (
    <div className="WindSpeedCard">
      <Card title="Wind Speed">
        <GaugeChart value={speed} unit="km/h" min={0} max={100} />
      </Card>
    </div>
  );
}

export default WindSpeedCard;
