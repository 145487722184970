// TODO: handle this better. Use node env variables?
const SELECTED_FARM = 'firstDairy';
// const SELECTED_FARM = 'aceFarm';

const FARMS = {
  firstDairy: {
    appTitle: 'First Dairy Holdings Weather Station (FIDAF-WS)',
    weatherLinkStationId: 129874,
    weatherStations: [
      {
        name: 'FD - 7A',
        id: 3,
        location: 'indoor'
      },
      {
        name: 'FD - 7B',
        id: 4,
        location: 'indoor'
      },
      {
        name: 'FD - 8A',
        id: 2,
        location: 'indoor'
      },
      {
        name: 'FD - 8B',
        id: 6,
        location: 'indoor'
      },
      {
        name: 'FD - 9A',
        id: 1,
        location: 'indoor'
      },
      {
        name: 'FD - 9B',
        id: 5,
        location: 'indoor'
      },
      {
        name: 'FD - OA',
        id: 7,
        location: 'outdoor'
      }
    ],
    cctvs: [
      { name: 'FD - 7A', url: 'http://first-dairy-cctv.ddns.net:6100' },
      { name: 'FD - 7B', url: 'http://first-dairy-cctv.ddns.net:6900' },
      { name: 'FD - 8A', url: 'http://first-dairy-cctv.ddns.net:6700' },
      { name: 'FD - 8B', url: 'http://first-dairy-cctv.ddns.net:7300' },
      { name: 'FD - 9A', url: 'http://first-dairy-cctv.ddns.net:5500' },
      { name: 'FD - 9B', url: 'http://first-dairy-cctv.ddns.net:5600' }
    ],
    soilDataLoggers: [
      // dummy data loggers. Uncomment to see its UI features.
      // { name: 'FD - S1', id: 1 },
      // { name: 'FD - S2', id: 2 },
      // { name: 'FD - S3', id: 3 },
      // { name: 'FD - S4', id: 4 }
    ]
  },
  aceFarm: {
    appTitle: 'Ace Farm Weather Station (ACE-WS)',
    weatherLinkStationId: 133234,
    weatherStations: [
      {
        name: 'ACE - 1A',
        id: 1,
        location: 'indoor'
      }
    ],
    cctvs: [{ name: 'ACE - 1A', url: 'http://ace-farm-cctv.ddns.net:6800/' }],
    soilDataLoggers: []
  }
};

const farms = { selectedFarmData: FARMS[SELECTED_FARM] };
export default farms;
